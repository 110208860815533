<template>

<!-- <section id="contact"> -->
  <div class="contact1 container">
    <br>
    <div class="row">

      <div class="col-sm-6">
        <form>
          <div class="form-group">
            <label for="name">Name</label>
            <input type="text" v-model="sender.name" class="form-control" id="name" placeholder="Your Name" required="">
          </div>

          <div class="form-group">
            <label for="email">Email</label>
            <input type="text" v-model="sender.email" class="form-control" id="email" placeholder="Your Email Address" required="">
          </div>

          <div class="form-group">
            <label for="message">Message</label>
              <textarea v-model="sender.message" class="form-control" id="message" rows="3"></textarea>
          </div>
          <button class="btn btn-info" v-on:click.prevent="sendMessage">Send Message</button>

        </form>
        <br>
        <p class="text-info" align="left">{{alert}}</p>
      </div>

      <div class="col-sm-6" align="center"> 
        <div class="contact icon">
          <i class="fa fa-map-marker"></i>
        </div>
        <p>Atlanta, GA, USA</p>
        <br>
        <div class="contact icon">
          <i class="fas fa-envelope"></i></div>
          <p>admin@signledger.com
          </p>
        <br>
        <div class="contact icon">
            <i class="fas fa-phone"></i>
        </div>
        <p>001 770 547 0142
        </p>
      </div> 


    </div>

  </div>

  <!-- </section> -->
</template>

<script>
import axios from "axios"

export default {
  name: 'contact',
  data () {
    return {
      sender: {},
      alert:''
    }
  },

  methods:{
      sendMessage(senderName, senderEmail, senderMsg) {
        var self = this;
        self.alert = null;

        if (!self.sender.name){
          self.alert = "Sender name is missing."; 
          return;
        }
        if (!self.sender.email){
          self.alert = "Sender email is missing."; 
          return;
        }
        if (!self.sender.message){
          self.alert = "Message is missing."; 
          return;
        }

        var formData = new FormData();
        formData.append("userName", self.sender.name);
        formData.append("userEmail", self.sender.email);
        formData.append("userMessage", self.sender.message);

        axios({
                method: 'POST',
                baseURL: process.env.VUE_APP_ROOT_API,
                url: '/Contact/',
                data: formData
            })
            .then(function (response) {
                if (response.data.err != ""){
                  self.alert = "Contact failed: " + response.data.errMsg;
                  return;
                }				
                self.$router.push('/');
            })
            .catch(function (error) {
                self.alert = "Contact failed: " + error
            });

      },
  },

  components:{
  }
}
</script>


